namespace Shared {

    export interface IPageModal {
        httpPageAction(type: string, currentPageId: string, newPageUrl: string, data: any, contentType: string, savePrevious?: boolean,
            successCallback?: Function, errorCallback?: Function);

        getPageModal(): JQuery<HTMLElement>;
        getConfirmationModal(): JQuery<HTMLElement>;
        showPageModal(): void;
        hidePageModal(): void;
    }

    //export interface ITable {
    //    initialiseTable(table): any;
    //}

    export class PageModal implements IPageModal {

        private pagePartialModal: JQuery<HTMLElement>;
        private confirmationModal: JQuery<HTMLElement>;

        getPageModal() {
            return this.pagePartialModal;
        }

        getConfirmationModal() {
            return this.confirmationModal;
        }

        showPageModal() {
            var modalOptions: Bootstrap.ModalOption = {
                backdrop: "static",
                keyboard: false,
                show: true
            };
            this.pagePartialModal.modal(modalOptions);
        }

        hidePageModal() {
            this.pagePartialModal.modal("hide");
        }

        httpPageAction(type: string, currentPageId: string, newPageUrl: string, data: any, contentType: string, savePrevious: boolean = false,
            successCallback: Function = () => { }, errorCallback: Function = () => { }) {

            var _this = this;

            if (!$('#pageModalPartial').length) {
                var currentPage = $("#" + currentPageId);
                currentPage.append(createModal());
            }

            this.pagePartialModal = $('#pageModalPartial');

            if (savePrevious) {
                displaySaveDialog(currentPageId);
            }
            else {
                displayPage(type, newPageUrl, data, contentType);
            }

            function displayPage(type: string, url: string, data: any, contentType: string) {
                requestPageAjax(type, url, data, contentType)
                    .then(
                        result => {
                            var modalOptions: Bootstrap.ModalOption = {
                                backdrop: "static",
                                keyboard: false,
                                show: true
                            };
                            _this.pagePartialModal.html((<HTMLElement>result));
                            _this.pagePartialModal.modal(modalOptions);
                            successCallback(data);
                        },
                        error => {
                            console.log(error);
                            errorCallback(data);
                        });
            }

            function createModal() {
                var partialModalDiv = document.createElement("div");
                partialModalDiv.id = "pageModalPartial";
                partialModalDiv.className = "modal";
                partialModalDiv.tabIndex = -1;
                partialModalDiv.setAttribute("role", "dialog");
                return partialModalDiv;
            }

            function displaySaveDialog(currentPageId: string) {

                if (!$('#confirmationModal').length) {
                    var currentPage = $("#" + currentPageId);
                    currentPage.append(createConfirmationDialog());

                    _this.confirmationModal = $('#confirmationModal');

                    _this.confirmationModal.find("#continueModalButton").on('click', () => {
                        _this.confirmationModal.modal("hide");
                        displayPage(type, newPageUrl, data, contentType);
                    });

                    _this.confirmationModal.find("#cancelModalButton").on('click', () => {
                        var modalOptions: Bootstrap.ModalOption = {
                            backdrop: "static",
                            keyboard: false,
                            show: true
                        };
                        _this.pagePartialModal.modal(modalOptions);
                        _this.confirmationModal.modal("hide");
                    });
                }
                else {
                    _this.confirmationModal = $('#confirmationModal');
                }


                var modalOptions: Bootstrap.ModalOption = {
                    backdrop: "static",
                    keyboard: false,
                    show: true
                };

                _this.pagePartialModal.modal('hide');
                _this.confirmationModal.modal(modalOptions);

            }

            function createConfirmationDialog() {

                var confirmationModal = document.createElement("div");
                confirmationModal.id = "confirmationModal";
                confirmationModal.className = "modal";
                confirmationModal.tabIndex = -1;
                confirmationModal.setAttribute("role", "dialog");

                confirmationModal.innerHTML = `
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Save Changes?</h5>
            </div>
            <div class="modal-body">
                <span id="confirmationModalBodySpan">Any unsaved changes will be lost!</span>
            </div>
            <div class="modal-footer">
                <button id="continueModalButton" type="button" class="btn btn-primary">Continue</button>
                <button id="cancelModalButton" type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>`;

                return confirmationModal;
            }

            function requestPageAjax(type: string, url: string, data: any, contentType: string) {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        type: type,
                        url: url,
                        data: data, // JSON.stringify({ "Data": data }),
                        //dataType: "html",
                        contentType: contentType,
                        success: (data) => {
                            resolve(data);
                        },
                        error: function (XMLHttpRequest, textStatus, errorThrown) {
                            reject(new Error("Status: " + XMLHttpRequest.status + (errorThrown ? (" Error: " + errorThrown) : "")));
                        }
                    })
                })
            }
        }
    }

    //export class Table implements ITable {
    //    private reloadTable = true;
    //    // Reloads the table st startup with the correct number of rows based on available space
    //    initialiseTable(table) {
    //        if (this.reloadTable) {
    //            this.reloadTable = false;
    //            table.search("").draw();
    //        }
    //    }
    //}
}